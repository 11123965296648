* {
  padding: 0;
  margin: 0;
  font-family: cursive;
}

.container {
  max-width: 1517px;
  margin: 0 auto;
}

.loop-Image {
  animation: rotate-animation 5s infinite;
}

@keyframes rotate-animation {
  0% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(10deg);
  }
}/*# sourceMappingURL=index.css.map */